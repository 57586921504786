import axios from "axios";
import { ERROR_MESSAGE } from "core-lib/constants/network";
import errorHandler from "core-lib/utils/error_handler";
import { checkMobileOS, isDataValid } from "core-lib/utils/helpers";
import { API_SECRET } from "../config/Env";
import { genericErrorHandler } from "./genericErrorHandler";
import { getDeviceId, getNativeVersions, isParkPlusWebView } from "./source";
import { isServer } from "./utils";

type ApiTypes = {
  response?: { [key: string]: any };
  header?: { [key: string]: any };
  error?: string | { [key: string]: any };
  status?: number;
  toast?: any;
  timeout?: number;
};

export function getMethod({
  endPoint,
  headers = { "Cache-Control": "no-cache" },
  params = null,
  enableErrorHandling = false,
  timeout = 50000,
}: {
  endPoint: string;
  headers?: any;
  params?: { [key: string]: string | number };
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  return (
    axios
      .get(`${endPoint}`, { headers, params , timeout})
      .then((response) => ({
        response: response?.data,
        headers: response?.headers,
        toast: errorHandler(response),
        status: response?.status,
      }))
      // eslint-disable-next-line arrow-body-style
      .catch((error) => {
        if (enableErrorHandling) {
          genericErrorHandler(error?.response);
        }
        return {
          error: error?.response?.data ?? ERROR_MESSAGE.genericFailure,
          headers: error?.response?.headers,
          toast: errorHandler(error?.response),
          errorData: error?.response,
          status: error?.response?.status,
        };
      })
  );
}

export function postMethod({
  endPoint,
  body,
  headers = null,
  enableErrorHandling = false,
  timeout = 50000
}: {
  endPoint: string;
  body: { [key: string]: any } | null;
  headers?: Headers | any;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let headerData: any = null;
  let errorMessage = "";
  if (isDataValid(headers)) {
    if (headers?.has && headers.has("Content-Type")) {
      headerData = { headers };
    } else {
      headerData = {
        headers: { ...headers, "Content-Type": "application/json" },
      };
    }
  }

  return axios
    .post(`${endPoint}`, body, { ...headerData, timeout }) 
    .then((response) => ({
      response: response?.data,
      status: response?.status,
    }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
        status: error?.response?.status,
      };
    });
}

export function putMethod({
  endPoint,
  body,
  headers = null,
  enableErrorHandling = false,
  timeout = 50000 
}: {
  endPoint: string;
  body: { [key: string]: any } | null;
  headers?: any;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let headerData: any = null;
  let errorMessage = "";

  if (isDataValid(headers)) {
    headerData = {
      headers: { ...headers, "Content-Type": "application/json" },
    };
  }

  return axios
    .put(`${endPoint}`, body, { ...headerData, timeout }) 
    .then((response) => ({ response: response.data, status: response.status }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
      };
    });
}

export function patchMethod({
  endPoint,
  body,
  headers = null,
  enableErrorHandling = false,
  timeout = 50000 
}: {
  endPoint: string;
  body: { [key: string]: any } | null;
  headers?: any;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let headerData: any = null;
  let errorMessage = "";
  
  if (isDataValid(headers)) {
    headerData = { headers };
  }
  
  return axios
    .patch(`${endPoint}`, body, { ...headerData, timeout }) 
    .then((response) => ({ response: response.data, status: response.status }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
      };
    });
}
export function deleteMethod({
  endPoint,
  body = null,
  headers = { "Content-Type": "application/json" },
  enableErrorHandling = false,
  timeout = 50000 // Default timeout of 50 seconds
}: {
  endPoint: string;
  body?: { [key: string]: any } | null;
  headers?: { [key: string]: any } | null;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let errorMessage = "";
  let headerData: any = null;

  if (isDataValid(headers)) {
    headerData = { ...headers };
  }

  const data = { ...body };

  return axios
    .delete(`${endPoint}`, { data, headers: headerData, timeout }) 
    .then((response) => ({ response: response.data, status: response.status }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
      };
    });
}


export function setAuthHeaders(token) {
  token && (axios.defaults.headers.common.Authorization = token);
}

export function removeAuthHeaders() {
  delete axios.defaults.headers.common["Authorization"];
}

export function setClientHeaders(
  options = null,
  clientId = API_SECRET.clientId,
  clientSecret = API_SECRET.clientSecret
) {
  axios.defaults.headers.common["client-id"] = clientId;
  axios.defaults.headers.common["client-secret"] = clientSecret;
  axios.defaults.headers.common.platform = "web";
  axios.defaults.headers.common["package-name"] = "web.pwa";
  if (!isServer()) {
    const isPPWebview = isParkPlusWebView();
    axios.defaults.headers.common["app-name"] = isPPWebview
      ? "B2C"
      : "Park+ PWA";
    if (isPPWebview) {
      axios.defaults.headers.common["native-platform"] = checkMobileOS();
    }
  }

  const { appVersion, osVersion } = getNativeVersions(options);
  if (appVersion) {
    axios.defaults.headers.common["version-name"] = appVersion;
  }
  if (osVersion) {
    axios.defaults.headers.common["os-version"] = osVersion;
  }
  const deviceId = getDeviceId(options);
  if (deviceId) {
    axios.defaults.headers.common["device-id"] = deviceId;
  }
  axios.defaults.timeout = 50000;
}
