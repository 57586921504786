export const CLOSE_SCREEN_EVENT = "closeScreen";
export const FETCH_CONTACT_EVENT = "fetchContact";
export const GET_IMAGE_EVENT = "fetchImage";
export const SHARE_EVENT = "share";
export const CALL_EVENT = "call";
export const PAYTM_ADD_MONEY_EVENT = "paytmAddMoney";
export const OPEN_QR_CODE = "openQrCode";
export const DOWNLOAD_FILE_EVENT = "downloadFile";
export const OPEN_WEB_VIEW_EVENT = "openWebView";
export const HANDLE_HEADER = "handleHeader";
export const SHOW_BACK_BUTTON = "showBackButton";
export const HIDE_BACK_BUTTON = "hideBackButton";
export const SET_WALLPAPER = "setWallPaper";
export const TRACK_NATIVE_APP_EVENT = "fireAnalyticEvent";
export const ENABLE_NOTIFICATION = "enableNotifications";
export const NATIVE_RAZORPAY_CALLBACK = "nativeRazorpayCallback";
export const SHOW_IN_APP_REVIEW = "showInAppReview";
export const ENABLE_VACCINE_NOTIFICATION = "enableVaccineNotifications";
export const OPEN_IN_BROWSER = "openInBrowser";
export const MAKE_UPI_PAYMENT = "makeUPIPayment";
export const OPEN_YOUTUBE_PLAYER = "OpenYoutubePlayer";
export const SHARE_WITHOUT_SCEREENSHOT = "share_without_screenshot";
export const OPEN_LINK_IN_BROWSER = "openInBrowser";
export const OPEN_SHORTS = "openShorts";
export const EKYC_DONE = "ekyc_done";
export const OPEN_FEATURE = "openFeature";
export const SCRIPT_EVENT = "scriptEvent";
export const NATIVE_LOGIN = "login";
export const FIRE_REFRESH_HOME = "fireRefreshHome";
export const REPLACE_TAG = "replaceTag";
export const UPDATE_STATUS_BAR = "updateStatusBar";
export const LOCK_TAG = "lockTag";
export const FETCH_NATIVE_LOCATION = "fetchLocation";
export const REVERSE_GEO_CODING = "reverseGeoCoding";
export const OPEN_FIT_KIT = "openFitKit";
export const NATIVE_MEDIA_UPLOAD = "nativeMediaUpload";
export const OPEN_SEPERATE_WEB_VIEW = "openSeparateWebView";
export const REFRESH_NATIVE_SCREEN = "refreshPage";
export const PWA_TO_NATIVE_AUTH = "pwaToNativeAuth";
export const GET_FINGERPRINT_FROM_NATIVE = "getFingerprint";
export const SEND_FINGERPRINT_DATA_TO_NATIVE = "sendFpData";
export const TICKET_CLOSED = "ticketClosed";
export const FETCH_NATIVE_CONTACT_LIST = "fetchUserContactList";
export const CHECK_NATIVE_PERMISSION = "checkPermission";
export const HAPTIC_FEEDBACK = "hapticFeedback";
export const PAGE_LOAD_SIGNAL = "pageLoadSignal";
export const MAKE_PAYMENT = "makePayment";
export const SCANNER = "scanner";

// For UCD app only
export const UCD_UPDATE_DEALER = "updateDealer";
export const GET_SHARED_PREFERENCE = "getSharedPreference";
export const SET_SHARED_PREFERENCE = "setSharedPreference";
export const UPI_PAYMENT_IOS = "upiPaymentIos";
export const GET_SECURE_KEY = "getSecureKey";

export const LOGIN_ON_THE_GO = "loginOnTheGo";
export const CONTACT = "contact";
export const TRACK_EVENT = "trackEvent";
export const LOCATION_EVENT = "location";
export const PERMISSION_EVENT = "permission";

export enum NativeScreenId {
  OrderHistory = 40,
  FastagRecharge = 23,
  ParkPlusGo = 39,
  ParkingBooking = 39,
  ParkingBookingPostPurchase = 42,
  ParkingBookingMap = 4,
  UploadParkingReceipt = 43,
  Rewards = 58,
  BuyFastag = 22,
  Wallet = 28,
  ParkingDetail = 33,
  Fuel = 75,
  Webview = 10,
  VehicleDetails = 5,
  HomePage = 77,
  MyVehicle = 15,
  TagScanner = 106,
  AddMoneyToWallet = 29,
  Login = 88,
  BuyGiftVoucher=103
}

export enum NativeLoadingSource {
  Android = "androidparkplus",
  Ios = "iosparkplus",
}
