export const HTMEDIA = {
  value: 'htmedia',
  referrer: 'hindustantimes.com',
  purchaseSuccessMsg: 'purchase-payment-success',
  purchaseFailMsg: 'purchase-payment-failure',
  rechargeSuccessMsg: 'purchase-recharge-success,',
  rechargeFailMsg: 'purchase-recharge-failure',
};

export const DRIVEU = {
  value: 'driveu',
};

export const CARINFO = {
  value: 'carinfo',
};

export const FLIPKART = {
  value: 'flipkart',
};

export const INTERMILES = {
  value: 'intermiles',
};

export const KRAHEJA = {
  value: 'kraheja',
};

export const WHATSAPP = {
  value: 'whatsapp',
};

export const MYGATE = {
  value: 'mygate',
};

export const QUICKRIDE = {
  value: 'quickride',
};

export const OLX = {
  value: 'olx',
};

export const TRADEU = {
  value: 'tradeu',
};

export const HANGOVER = {
  value: 'hangover',
};

export const FACEBOOK = {
  value: 'facebook',
};

export const GOOGLE = {
  value: 'google',
};

export const HCL = {
  value: 'hcl',
};

export const FREECHARGE = {
  value: 'freecharge',
};

export const TOI = {
  value: 'toi',
};

export const QUIXOTE = {
  value: 'quixote',
  host: 'https://apieb.quixote.in',
  successUrl:
    '/wi/api/v2/parkplus/Success?mobile_number=<mn>&txn_id=<txn_id>&txn_amount=<txn_amt>&order_id=<order_id>&jwt=<jwt>&order_type=<order_type>',
  failureUrl: '/wi/api/v2/parkplus/Failure?order_id=<order_id>',
};

export const PARKPLUS = {
  value: 'parkplus',
  referrer: 'parkplus.io',
};

export const BMS = {
  value: 'BookMyShow',
};
export const PVR = {
  value: 'PVR',
};

export const MG = {
  value: 'MG-MOTORS',
};

export const BROOKFIELD = {
  value: 'brookfield',
};

export const MAGICPIN = {
  value: 'magicpin',
};

export const ARYO = {
  value: 'aryo',
};
